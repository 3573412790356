import { PROFILE_FIELDS } from '../AIConstants'

export const profileHeaders = [
  {
    'name': '',
    'noWrap': 1,
    'control': 'overlay',
    'textType': '',
    'textAlign': 'left',
    'separator': true,
    'hideInMobile': false,
    'isSortable': false,
    'staticColWidth': 44,
    'isToggledInMobile': true,
    'showCollapseIconInMobile': false,
    'showEllipsis': false,
    'leftPosWithToggle': 0,
    'leftPosWithoutToggle': 44,
    'staticMobileColWidth': 44,
    'staticTabletColWidth': 44
  },
  {
    'name': PROFILE_FIELDS.PERAISecurityDescription,
    'noWrap': 1,
    'control': '',
    'textType': 'link',
    'textAlign': 'left',
    'separator': true,
    'hideInMobile': false,
    'isSortable': true,
    'staticColWidth': 124,
    'isToggledInMobile': false,
    'showCollapseIconInMobile': true,
    'showEllipsis': true,
    'leftPosWithToggle': 44,
    'leftPosWithoutToggle': 88,
    'staticMobileColWidth': 90,
    'staticTabletColWidth': 100
  },
  {
    'name': PROFILE_FIELDS.PERAICusip,
    'noWrap': 1,
    'control': '',
    'textType': '',
    'textAlign': 'left',
    'separator': true,
    'hideInMobile': false,
    'isSortable': true,
    'staticColWidth': 0,
    'isToggledInMobile': false,
    'showCollapseIconInMobile': false,
    'showEllipsis': true,
    'leftPosWithToggle': 0,
    'leftPosWithoutToggle': 0,
    'staticMobileColWidth': 0,
    'staticTabletColWidth': 0,
    'SortDirection': true
  },
  {
    'name': PROFILE_FIELDS.PERAIProductFeeIndicator,
    'noWrap': 1,
    'control': '',
    'textType': '',
    'textAlign': 'left',
    'separator': true,
    'hideInMobile': false,
    'isSortable': true,
    'staticColWidth': 0,
    'isToggledInMobile': false,
    'showCollapseIconInMobile': false,
    'showEllipsis': false,
    'leftPosWithToggle': 0,
    'leftPosWithoutToggle': 0,
    'staticMobileColWidth': 0,
    'staticTabletColWidth': 0
  },
  {
    'name': PROFILE_FIELDS.PERAIMinorProductCode,
    'noWrap': 1,
    'control': '',
    'textType': '',
    'textAlign': 'left',
    'separator': true,
    'hideInMobile': false,
    'isSortable': true,
    'staticColWidth': 0,
    'isToggledInMobile': false,
    'showCollapseIconInMobile': false,
    'showEllipsis': false,
    'leftPosWithToggle': 0,
    'leftPosWithoutToggle': 0,
    'staticMobileColWidth': 0,
    'staticTabletColWidth': 0
  },
  {
    'name': PROFILE_FIELDS.PERAIDepositoryID,
    'noWrap': 1,
    'control': '',
    'textType': '',
    'textAlign': 'left',
    'separator': true,
    'hideInMobile': false,
    'isSortable': true,
    'staticColWidth': 0,
    'isToggledInMobile': false,
    'showCollapseIconInMobile': false,
    'showEllipsis': false,
    'leftPosWithToggle': 0,
    'leftPosWithoutToggle': 0,
    'staticMobileColWidth': 0,
    'staticTabletColWidth': 0
  },
  {
    'name': PROFILE_FIELDS.PERAISecRegistered,
    'noWrap': 1,
    'control': '',
    'textType': '',
    'textAlign': 'left',
    'separator': true,
    'hideInMobile': false,
    'isSortable': true,
    'staticColWidth': 0,
    'isToggledInMobile': false,
    'showCollapseIconInMobile': false,
    'showEllipsis': false,
    'leftPosWithToggle': 0,
    'leftPosWithoutToggle': 0,
    'staticMobileColWidth': 0,
    'staticTabletColWidth': 0
  },
  {
    'name': PROFILE_FIELDS.PERAIFundStructure,
    'noWrap': 1,
    'control': '',
    'textType': '',
    'textAlign': 'left',
    'separator': true,
    'hideInMobile': false,
    'isSortable': true,
    'staticColWidth': 0,
    'isToggledInMobile': false,
    'showCollapseIconInMobile': false,
    'showEllipsis': false,
    'leftPosWithToggle': 0,
    'leftPosWithoutToggle': 0,
    'staticMobileColWidth': 0,
    'staticTabletColWidth': 0
  }
]
