import * as Sentry from '@sentry/browser'
import * as Integrations from '@sentry/integrations';

import packageJson from '../../package.json'

export default function initSentry () {
  if (window.MD.ENV !== 'localhost') {
    Sentry.init({
      dsn: window.MD.SENTRY_DNS,
      environment: window.MD.ENV,
      release: packageJson.version,
      integrations: [
        new Integrations.CaptureConsole({
          levels: ['error']
        })
      ]
    });
  }
}
