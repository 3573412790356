/* eslint-disable no-eval */
import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { handleWindowResize, deviceType, getDeviceType } from '../../../utils/utilities'
import styles from './StockValuationAvg.module.scss'
import Loader from '../../Lib/common/Loader'
import { formatNumber } from '../../../utils/formatter'
import { STOCK_VALUATION_AVG } from '../../../utils/appConstants'

function StockValuationAvg ({ symbolData, aggregateData, symbol, children, containerStyle, handlePageClick }) {
  const [device, setDevice] = useState(getDeviceType())
  const [dataObj] = useState(setCurrentValuationState())
  useEffect(() => {
    handleWindowResize((response) => {
      setDevice(response)
    })
  }, [])

  function getComputedValue (item, data) {
    let expressionValue = getEvalValue(item, data)
    let value = formatNumber(expressionValue, {
      precision: STOCK_VALUATION_AVG.PRECISION,
      negSign: true,
      postFix: item.type
    })
    return value
  }

  function getEvalValue (value, data) {
    let val = ''
    try {
      val = eval(value)
    } catch (ex) {
      val = null
    }
    return val
  }
  function setCurrentValuationState () {
    let data = symbolData
    let obj = {}
    let arrayObj = []
    STOCK_VALUATION_AVG.DATAMAP.forEach((item) => {
      obj = {}
      obj['label'] = item.key
      obj['value'] = getComputedValue(item.value, symbolData.data)
      obj['indVal'] = getComputedValue(item.value, aggregateData.data)
      obj['column'] = item && item['column']
      obj['type'] = item && item['type']
      arrayObj.push(obj)
    })
    let dummyState = {
      isError: data ? data.isError : null,
      isData: data ? data.isData : false,
      isLoading: data ? data.isLoading : true,
      data: arrayObj,
      header: STOCK_VALUATION_AVG.HEADER
      // footer: STOCK_VALUATION.FOOTER
    }
    return dummyState
  }
  function getMainContent () {
    return deviceType.Ipad !== device ? getBodyStructure(dataObj.data) : getBodyStructureIpad()
  }

  function handleClick () {
    handlePageClick && handlePageClick()
  }

  function getBodyStructure (data) {
    let mainContent = data.map((item, idx) => {
      let diffPercent = item.indVal - item.value
      let positive = diffPercent > 0 ? diffPercent > 100 ? 100 : diffPercent : 0
      let negative = diffPercent < 0 ? diffPercent < -100 ? 100 : -(diffPercent) : 0
      return (
        <div className={`${styles.mainContainer}`}>
          <div key={idx} className={`${styles.mainContent} ${!dataObj.footer ? styles.noBottomLastChild : ''}`}>
            <div className={styles.mainContentLabel}>{item.label}</div>
            <div className={styles.mainContentValue} style={item.valueStyling}>{`${item.value}${item.type ? '%' : ''}`}</div>
            <div className={styles.mainContentValue} style={item.valueStyling}>{`${item.indVal}${item.type ? '%' : ''}`}</div>

          </div><div className={styles.measurement}>
            <div className={styles.leftSection}>
              <div className={styles.positive} style={{ width: positive > 0 && positive < 1 ? 1 : positive }} />
            </div><div className={styles.customPipe} />
            <div className={styles.rightSection}>
              <div className={styles.negative} style={{ width: negative > 0 && negative < 1 ? 1 : negative }} />
            </div>
          </div></div>)
    })
    const industryAvgText = 'Ind Avg'
    return (<section>
      <div className={`${styles.mainContentHeader}`}>
        <div className={`${styles.mainContent} ${!dataObj.footer ? styles.noBottomLastChild : ''}`}>
          <div className={`${styles.mainContentHeaderLabel}`}><div className={styles.mainContentLabel}>Name</div></div>
          <div className={`${styles.symbolHeader} ${styles.mainContentValue}`}><div className={styles.circle} /><div className={styles.symbolDescription}>{symbol}</div></div>
          <div className={`${styles.symbolHeader} ${styles.mainContentValue}`}><div className={styles.customHeaderPipe} /><span className={styles.customPipeContainer}> {industryAvgText}</span></div>
        </div>
      </div>
      {mainContent} </section>)
  }
  function getBodyStructureIpad () {
    let leftMainContent = getBodyStructure(dataObj.data.filter(e => !e.column))
    let rightMainContent = getBodyStructure(dataObj.data.filter(e => e.column))
    return (<div className={styles.ipadContentContainer} data-table-structure='table-ipad-container'>
      <div className={styles.ipadContentLeft}>
        {leftMainContent}
      </div>
      <div className={styles.ipadContentRight}>
        {rightMainContent}
      </div>
    </div>)
  }
  const content = dataObj.isLoading ? (<div className={styles.stockValuationAvgContainer}>
    <Loader spinnerSize={'2x'} ready={!dataObj.isLoading} />
  </div>) : !children ? getMainContent() : null

  return (
    <div className={styles.stockValuationAvgContainer} style={containerStyle}>
      <header>
        <div className={styles.headerTabularStructure}>{dataObj.header}{dataObj.subHeader ? <span className={styles.headerSubTabularStructure}>{dataObj.subHeader}</span> : ''}</div>
      </header>
      {children || content}
      {dataObj.footer && <footer>
        <button className={styles.footerButton} onClick={handleClick}>{dataObj.footer}</button>
      </footer>
      }
    </div>
  )
}

export default StockValuationAvg

StockValuationAvg.propTypes = {
  symbolData: PropTypes.object.isRequired,
  aggregateData: PropTypes.object.isRequired,
  children: PropTypes.node,
  containerStyle: PropTypes.object,
  handlePageClick: PropTypes.func,
  symbol: PropTypes.string
}
