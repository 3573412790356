import React from 'react'
import PropTypes from 'prop-types'
import styles from './StockHighlightsStyles.module.scss'
import Loader from '../../Lib/common/Loader'
import StockHighlightsItem from '../StockHighlights/StockHighlightsItem/StockHighlightsItem'
import { URLS, DATE_FORMAT } from '../../../utils/appConstants'
import DataRequest from '../../../services/DataRequest'
import {formatDate, formatNumber} from '../../../utils/formatter'
import {deviceType, getDeviceType, handleWindowResize} from '../../../utils/utilities'
import {EVENT_NAMES} from '../../../utils/f2Constants'
import {registerF2OnEvent, registerAccessTokenUpdatedF2OnEvent} from '../../../utils/f2Methods'
import { getQuoteData } from '../../../shared/dataMethods'

const TEXT_NULL_VALUE = '--'
class StockHighlights extends React.Component {
  constructor (props) {
    super(props)
    this.state = this.getInitialState(props.symbol)
    this.spinnerStyle = props.spinnerStyle || {'minWidth': '350px'}
    this.defaultMultiplication = 100
  }

  componentDidMount () {
    handleWindowResize((response) => {
      this.setState({device: response})
    })

    registerF2OnEvent(EVENT_NAMES.SYMBOL_CHANGED_EVENT, (symbolUpdatedObject) => {
      this.setState({symbol: symbolUpdatedObject.symbol,
        isLoading: true,
        stockHighlightsData: this.getInitialStateOfStockData(),
        venueXid: null
      }, () => {
        this.getVenueXidFromXref()
      })
    })

    registerAccessTokenUpdatedF2OnEvent(() => {
      this.setState({isLoading: true}, this.getVenueXidFromXref())
    })
    this.getVenueXidFromXref()
  }

  getInitialState (symbol) {
    return {
      isError: false,
      isLoading: true,
      symbol: symbol,
      venueXid: null,
      stockHighlightsData: this.getInitialStateOfStockData(),
      dividendFrequencyStyle: {'textTransform': 'capitalize'},
      device: getDeviceType()
    }
  }
  getInitialStateOfStockData () {
    return {
      open: TEXT_NULL_VALUE,
      previousClose: TEXT_NULL_VALUE,
      volume: TEXT_NULL_VALUE,
      marketCap: TEXT_NULL_VALUE,
      annualizedDividend: TEXT_NULL_VALUE,
      dividendYield: TEXT_NULL_VALUE,
      dividendFrequency: TEXT_NULL_VALUE,
      currentDividend: TEXT_NULL_VALUE,
      exDividendDate: TEXT_NULL_VALUE,
      paymentDate: TEXT_NULL_VALUE,
      standardDeviation: TEXT_NULL_VALUE,
      beta: TEXT_NULL_VALUE
    }
  }
  getVenueXidFromXref () {
    let callback = (response) => {
      if (!response.isDataRequestComplete) return

      if (!response.isError) {
        const responseData = response?.data?.xids
        if (responseData?.venue) {
          this.setState({venueXid: responseData.venue}, this.getStockHighlightData(responseData.venue))
        } else {
          this.setState({isError: true, isLoading: false})
        }
      } else {
        this.setState({isError: true, isLoading: false})
      }
    }

    DataRequest.execute(
      `${URLS.PLATFORM.XREFGETSYMBOL}/` + this.state.symbol,
      {
        params: {
          isCaseSensitive: false
        }
      },
      callback,
      null
    )
  }

  getStockHighlightData (venueXid) {
    this.getQuoteCall(venueXid)
    this.getCorporateActionDividend(venueXid)
    this.getPricingCurrentDayEndStats(venueXid)
  }

  getQuoteCall (venueXid) {
    let callback = (response) => {
      if (!response.isDataRequestComplete) return

      if (!response.isError) {
        const responseData = response.data && response.data.quotes[0] && response.data.quotes[0].data
        let stockHighlightsDataUpdated = Object.assign({}, this.state.stockHighlightsData)
        stockHighlightsDataUpdated.open = formatNumber(responseData.lastTrade.open, {precision: 2})
        stockHighlightsDataUpdated.previousClose = formatNumber(responseData.lastTrade.close, {precision: 2})
        stockHighlightsDataUpdated.volume = formatNumber(responseData.volume.last, {precision: 1, showMagnitude: true})
        stockHighlightsDataUpdated.marketCap = formatNumber(responseData.marketCap, {precision: 2, showMagnitude: true})
        this.setState({stockHighlightsData: stockHighlightsDataUpdated, isLoading: false})
      } else {
        this.setState({isError: true, isLoading: false})
      }
    }
    getQuoteData(venueXid, callback)
  }

  getCorporateActionDividend (venueXid) {
    let callback = (response) => {
      if (!response.isDataRequestComplete) return

      if (!response.isError) {
        const responseData = response && response.data
        let stockHighlightsDataUpdated = Object.assign({}, this.state.stockHighlightsData)
        stockHighlightsDataUpdated.annualizedDividend = formatNumber(responseData.trailingDividend.regularDividendTTM, {precision: 2})
        stockHighlightsDataUpdated.dividendYield = formatNumber(responseData.trailingDividend.regularDividendTTMYield, {postFix: true, precision: 2})
        stockHighlightsDataUpdated.dividendFrequency = responseData.currentDividend.frequency
        stockHighlightsDataUpdated.currentDividend = formatNumber(responseData.currentDividend.amount, {precision: 2})
        stockHighlightsDataUpdated.exDividendDate = formatDate(new Date(responseData.currentDividend.exDate), {format: DATE_FORMAT.MONTH_DAY_YEAR})
        stockHighlightsDataUpdated.paymentDate = formatDate(new Date(responseData.currentDividend.paymentDate), {format: DATE_FORMAT.MONTH_DAY_YEAR})
        this.setState({stockHighlightsData: stockHighlightsDataUpdated, isLoading: false})
      } else {
        this.setState({isError: true, isLoading: false})
      }
    }

    DataRequest.execute(
      `${URLS.PLATFORM.CORPORATEACTIONSDIVIDENDS}/` + venueXid + `/current`,
      {
        params: {
          outputCurrency: 'USD',
          adjustForSpinoffs: true
        }
      },
      callback,
      null
    )
  }

  getPricingCurrentDayEndStats (venueXid) {
    let callback = (response) => {
      if (!response.isDataRequestComplete) return

      if (!response.isError) {
        const responseData = response.data && response.data.volatility
        let stockHighlightsDataUpdated = Object.assign({}, this.state.stockHighlightsData)
        stockHighlightsDataUpdated.standardDeviation = formatNumber(responseData.standardDeviationWeekly3YearsAnnualized * this.defaultMultiplication, {postFix: true, precision: 2})
        stockHighlightsDataUpdated.beta = formatNumber(responseData.beta3years, {precision: 2})
        this.setState({stockHighlightsData: stockHighlightsDataUpdated, isLoading: false})
      } else {
        this.setState({isError: true, isLoading: false})
      }
    }

    DataRequest.execute(
      `${URLS.PLATFORM.PRICINGCURRENTENDDAYSTATS}/` + venueXid,
      {
        params: {}
      },
      callback,
      null
    )
  }

  stockHighlightIpadHtmlBody () {
    return <React.Fragment>
      <div className={styles.stockHighlightContent}>
        <div className={styles.stockHighlightIPadContainer}>
          <div className={styles.stockHighlightIPadDivLeft}>
            <StockHighlightsItem label={'Open'} value={this.state.stockHighlightsData.open} />
            <StockHighlightsItem label={'Previous Close'} value={this.state.stockHighlightsData.previousClose} />
            <StockHighlightsItem label={'Volume'} value={this.state.stockHighlightsData.volume} />
            <StockHighlightsItem label={'Market Cap'} value={this.state.stockHighlightsData.marketCap} />
            <StockHighlightsItem label={'Standard Deviation (3Yr)'} value={this.state.stockHighlightsData.standardDeviation} />
            <StockHighlightsItem label={'Beta (3Yr)'} value={this.state.stockHighlightsData.beta} />
          </div>
          <div className={styles.stockHighlightIPadDivRight}>
            <StockHighlightsItem label={'Annualized Dividend (TTM)'} value={this.state.stockHighlightsData.annualizedDividend} />
            <StockHighlightsItem label={'Dividend Yield'} value={this.state.stockHighlightsData.dividendYield} />
            <StockHighlightsItem label={'Dividend Frequency'} value={this.state.stockHighlightsData.dividendFrequency} valueStyling={this.state.dividendFrequencyStyle} />
            <StockHighlightsItem label={'Current Dividend'} value={this.state.stockHighlightsData.currentDividend} />
            <StockHighlightsItem label={'Ex-Dividend Date'} value={this.state.stockHighlightsData.exDividendDate} />
            <StockHighlightsItem label={'Payment Date'} value={this.state.stockHighlightsData.paymentDate} />
          </div>
        </div>
      </div>
      {/* <StockHighlightsFooter symbol={this.state.symbol} /> */}
    </React.Fragment>
  }

  stockHighlightHtmlBody () {
    if (this.state.isLoading) {
      return <Loader spinnerStyle={this.spinnerStyle} ready={false} msg={'Loading'} spinnerSize={'2x'} />
    }
    if (this.state.device === deviceType.Ipad) {
      return this.stockHighlightIpadHtmlBody()
    } else {
      return <React.Fragment>
        <div className={styles.stockHighlightContent}>
          <StockHighlightsItem label={'Open'} value={this.state.stockHighlightsData.open} />
          <StockHighlightsItem label={'Previous Close'} value={this.state.stockHighlightsData.previousClose} />
          <StockHighlightsItem label={'Volume'} value={this.state.stockHighlightsData.volume} />
          <StockHighlightsItem label={'Market Cap'} value={this.state.stockHighlightsData.marketCap} />
          <StockHighlightsItem label={'Annualized Dividend (TTM)'} value={this.state.stockHighlightsData.annualizedDividend} />
          <StockHighlightsItem label={'Dividend Yield'} value={this.state.stockHighlightsData.dividendYield} />
          <StockHighlightsItem label={'Dividend Frequency'} value={this.state.stockHighlightsData.dividendFrequency} valueStyling={this.state.dividendFrequencyStyle} />
          <StockHighlightsItem label={'Current Dividend'} value={this.state.stockHighlightsData.currentDividend} />
          <StockHighlightsItem label={'Ex-Dividend Date'} value={this.state.stockHighlightsData.exDividendDate} />
          <StockHighlightsItem label={'Payment Date'} value={this.state.stockHighlightsData.paymentDate} />
          <StockHighlightsItem label={'Standard Deviation (3Yr)'} value={this.state.stockHighlightsData.standardDeviation} />
          <StockHighlightsItem label={'Beta (3Yr)'} value={this.state.stockHighlightsData.beta} />
        </div>
        {/* <StockHighlightsFooter symbol={this.state.symbol} /> */}
      </React.Fragment>
    }
  }

  render () {
    return (
      <div className={styles.stockHightlightContainer}>
        <div className={styles.stockHighlightHeader}>{this.state.symbol} Highlights</div>
        {this.stockHighlightHtmlBody()}
      </div>
    )
  }
}

StockHighlights.propTypes = {
  spinnerStyle: PropTypes.object,
  symbol: PropTypes.string
}
export default StockHighlights
