/* eslint-disable */
import "babel-polyfill";
import React from "react";
import TestTokenEvents from "./ClientApplication/TokenEvents";
import styles from './DevelopComponentPage.module.scss'
import ChartsContainer from '../Charts/ChartsContainer'
import NewsFilterBar from "../News/NewsFilters/NewsFilterBar";
import NXIEsgFundContainer from "../NXIEsgComponents/TabsContainer/NXIEsgFundContainer";
import EquityEsgContainer from "../NXIEsgComponents/ESG/Equity/EquityEsgContainer"
// import OtherWaysToInvestContainer from "../Stocks/ESGScores/OtherWaysToInvest/OtherWaysToInvestContainer";
import FundMarketplace from '../WorkstreamII/pershing-netxwealth-universalsearch/components/FundMarkePlace/FundMarkePlace'
import WatchlistDetails from '../Watchlists/View/WatchlistDetails/WatchlistDetails'
import WatchlistsLanding from '../Watchlists/View/Watchlists'
import Ownership from '../Stocks/Ownership/Ownership'
import OptionsChainContainer from '../Stocks/Options/OptionsChainContainer'
import InsiderTransactionsIndividuals from "../Stocks/Ownership/InsiderTransactions/InsiderTransactionsIndividuals/InsiderTransactionsIndividuals";
import PeerGrowthRatesContainer from "../Stocks/PeerGrowthRates/PeerGrowthRatesContainer";
import PeerNews from "../Stocks/PeerNews/PeerNews";
import PeerPEContainer from "../Stocks/PeerPE/PeerPEContainer";
import Snapshot from "../Stocks/Pages/QuotesAndResearch/Snapshot/SnapshotPage";
import StockRatings from "../Stocks/Snapshot/StockRatings/StockRatings";
import GrowthMeasures from "../Stocks/Valuation/GrowthMeasures/GrowthMeasures";
import PeerValuationsContainer from "../Stocks/Valuation/PeerValuations/PeerValuationsContainer";
import AIMarketPlace from "../WorkstreamII/pershing-netxwealth-universalsearch/components/AIMarketPlace/AIMarketPlace";
import FundMarkePlace from "../WorkstreamII/pershing-netxwealth-universalsearch/components/FundMarkePlace/FundMarkePlace";
import initSentry from '../../services/sentry.js'

class TestPage extends React.Component {
  constructor() {
    super()
  }
  componentDidMount () {
    // setAccessToken()
    initSentry()
  }

  render () {
    return <div className={styles.container}>            
      {/* <EventsContainer date="2024-02-20" /> */}
      {/* <FundMarketplace /> */}
      {/* <InsiderTransactionsIndividuals venueXid='36276' /> */}
      {/* <PeerGrowthRatesContainer symbol='aapl'/> */}
      {/* <Snapshot symbol='aapl' /> */}
      {/* <StockRatings  symbol='aapl' /> */}
      {/* <GrowthMeasures symbol='aapl' /> */}
      {/* <PeerValuationsContainer symbol='aapl' /> */}
      {/* <PeerPEContainer symbol='aapl'/> */}
      {/* <PeerNews symbol='aapl' /> */}
      {/* <NewsForYou /> */}
      {/* <ChartsContainer symbol='aapl' /> */}
      {/* <HistoricalPrices symbol='CHRA'/> */}
      {/* <OtherWaysToInvestContainer symbol='msft'/> */}
      {/* <NewsFilterBar /> */}
      {/* <NewsAndResearch /> */}
      {/* <WatchlistsLanding /> */}
      {/* <WatchlistDetails watchlistId='066615FC-429F-478D-9935-10ADC388A3C1' /> */}
      {/* <FundRightPanelEsgContainer symbol='AAAIX' /> */}
      {/* <NXIEsgFundContainer symbol='AAAIX'  /> */}
      {/* <Ownership symbol={'AAPL'} /> */}
      
      <ChartsContainer symbol='aapl' />
      <TestTokenEvents />
    </div>
  }
}

export default TestPage