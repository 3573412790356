import React, {useEffect, useState, Fragment} from 'react'
import Carousel from '../../Lib/Carousal/Carousel'
import QuoteCard from '../../News/QuoteCard/QuoteCard'
import PropTypes from 'prop-types'
import styles from './NewsArticle.module.scss'
import DataRequest from '../../../services/DataRequest'
import {URLS, ModuleName} from '../../../utils/appConstants'
import Loader from '../../Lib/common/Loader'
import NoData from '../../Lib/NoData/NoData'
import {GetTagsFromCategories, sanitizeTeaser} from '../../Lib/common/CommonMethods'
import NewsHeader from '../NewsHeader/NewsHeader'
import { handleWindowResize, getDeviceType, deviceType } from '../../../utils/utilities'
import StockCard from '../../WorkstreamII/pershing-netxwealth-universalsearch/components/Shared/QuoteCardWithStockHighlights/QuoteCardWithStockHighlights'

const NewsArticle = ({ articleNewsDetailsData, availableSymbolList, isFund, isRealTime }) => {
  const { articleId, WSODIssue, Provider, DocumentDate, Headline, Category } = articleNewsDetailsData
  const [newsArticleData, setNewsArticleData] = useState(setInitialState())
  const [device, setDevice] = useState(getDeviceType())
  let spinnerStyle = {'minWidth': `220px`, 'height': '225px'}

  useEffect(() => {
    getNewsArticleData()
    handleWindowResize(resizeHandler)
  }, [])

  useEffect(() => {
    const modalPopup = document.getElementById('modalPopUpContentContainer')
    if (modalPopup) {
      modalPopup.addEventListener('scroll', function (e) {
        const quoteCard = document.getElementById('quoteCardCOntainer')
        if (quoteCard) {
          quoteCard.setAttribute('style', `margin-top:${e.target.scrollTop}px`)
        }
      })
    }
  }, [])

  const setCategoryTags = (categoryTags) => {
    const categories = categoryTags.map(item => ({ category: item.trim() }));
    if (!categories.length) {
      return;
    }

    DataRequest.execute(
      `${URLS.CUSTOM.SET_VIEWED_NEWS_CATEGORY}`,
      {
        method: 'POST',
        postData: categories,
        params: { isNewsRead: true }
      },
      response => {
        if (!response.isDataRequestComplete) return
        if (!response.isError && response.data) {
        }
      },
      null
    )
  }
  function resizeHandler (device) {
    setDevice(device)
  }

  function getNewsArticleData () {
    DataRequest.execute(`${URLS.CUSTOM.NEWSARTICLE}/${articleId}`, null,
      newsArticleCallbackHandler,
      null)
  }

  function newsArticleCallbackHandler (response) {
    if (response.isError) {
      setNewsArticleData({
        isError: response.isError,
        isLoading: false
      })
    } else if (response?.data) {
      setNewsArticleData({
        data: response.data,
        isLoading: false
      })
    }
  }
  function setInitialState () {
    return {
      isLoading: true,
      data: null
    }
  }

  function isDataAvailable () {
    return newsArticleData.isError || !newsArticleData.data
  }

  function getQuoteCard (venueXidList) {
    if (!venueXidList || venueXidList.length === 0) { return null }
    if (isFund) {
      let stockcardStyle = { width: '220px', padding: '10px 0 0px', boxShadow: 'none', border: '1px solid rgba(0,0,0,.07)' }
      return <Fragment>
        <div id='quoteCardCOntainer'>
          {venueXidList.length === 1
            ? <StockCard type='ETF' moduleType={ModuleName.ETF_RESEARCH_SNAPSHOT} id={'quoteCard_' + venueXidList[0]} key={'quoteCard_' + venueXidList[0]} venueXid={venueXidList[0]} style={stockcardStyle} spinnerStyle={spinnerStyle} standAlone />
            : <Carousel id={styles.newsArticleQuoteChartPopUp} isTiltEnable>
              {venueXidList.map((item, idx) => {
                return (<StockCard type='ETF' moduleType={ModuleName.ETF_RESEARCH_SNAPSHOT} id={'quoteCard_' + item} key={'quoteCard_' + item} venueXid={item} style={stockcardStyle} spinnerStyle={spinnerStyle} standAlone />)
              })}
            </Carousel>
          }
        </div>

      </Fragment>
    } else {
      return <Fragment>
        <div id='quoteCardCOntainer'>
          {venueXidList.length === 1
            ? <QuoteCard id='quoteCardNews' venueXid={venueXidList[0]} showViewQuoteCardButton />
            : <Carousel id={styles.newsArticleQuoteChartPopUp} isTiltEnable>
              {venueXidList.map((item, idx) => {
                return (<QuoteCard venueXid={item} id={'quoteCardNews_' + idx} key={'quoteCardNews_' + idx} isRealTime={isRealTime} showViewQuoteCardButton />)
              })}
            </Carousel>
          }
        </div>
      </Fragment>
    }
  }

  const fullStory = newsArticleData?.data?.fullStory ? sanitizeTeaser(newsArticleData.data.fullStory) : null

  if (newsArticleData.isLoading) {
    return (
      <div className={`${styles.modalPopUpContentContainer} ${styles.loaderContainer}`}>
        <Loader ready={!newsArticleData.isLoading}
          spinnerSize={'2x'}
          msg={'Loading'}
        />
      </div>)
  } else {
    let venueXidList = []
    if (device !== deviceType.Mobile && WSODIssue?.length > 0) {
      WSODIssue.split('|').forEach(item => {
        if (!isNaN(item) && (item !== '')) {
          const actualItem = parseInt(item)
          if (availableSymbolList.find(item => item.venueXid === actualItem)) {
            venueXidList.push(actualItem)
          }
        }
      })
    }
    const tagsList = Object.keys(GetTagsFromCategories(Category))
    return (
      <div className={`${styles.modalPopUpContentContainer}`}>
        <div className={`${venueXidList?.length > 0 ? styles.articleNewsDetailsContainer : styles.articleNewsContainer}`} style={isFund ? {'marginTop': '-15px'} : {}}>
          {
            isDataAvailable() ? <NoData hideBellIcon={false} />
              : <div>
                <NewsHeader
                  provider={Provider}
                  date={DocumentDate}
                  headline={Headline} />
                <div className={styles.articleNewsDetailsDescription}>
                  {
                    fullStory ? <pre className={styles.formatFullStory} dangerouslySetInnerHTML={{ __html: fullStory }} /> : null
                  }
                </div>
              </div>
          }
          {tagsList.length > 0 && <div className={styles.articleNewsDetailsRelatedTags}>
            <div className={styles.articleNewsDetailsRelatedTagsText}>Related Tags:</div>
            {tagsList.map((tag, index) => {
              return <div className={styles.articleNewsDetailsTagName} key={'articleNewsRelatedTag' + index}>{tag}</div>
            })}
            {setCategoryTags(tagsList)}
          </div>}

        </div>
        {
          getQuoteCard(venueXidList)
        }
      </div>
    )
  }
}
export default NewsArticle

NewsArticle.propTypes = {
  articleNewsDetailsData: PropTypes.object.isRequired,
  availableSymbolList: PropTypes.array,
  isFund: PropTypes.bool,
  isRealTime: PropTypes.bool
}
