import { textFormatter } from '../../utils/formatter'
// eslint-disable-next-line camelcase
import { MinorProductCodeToTextMapping, PROFILE_FIELDS, Y_N_MappingTo_Yes_No, FeeNonFeeMapping, RetirementMapping } from './AIConstants'

const searchType = 'AIScreener'

export const fillProfileTableColumnsData = (arrayToFill, item) => {
  return arrayToFill.push({
    searchType: searchType,
    [PROFILE_FIELDS.PERAISecurityDescription]: textFormatter(item.PERAISecurityDescription),
    [PROFILE_FIELDS.PERAICusip]: textFormatter(item.PERAICusip),
    [PROFILE_FIELDS.PERAIProductFeeIndicator]: textFormatter(FeeNonFeeMapping[item.PERAIProductFeeIndicator]),
    [PROFILE_FIELDS.PERAIMinorProductCode]: textFormatter(MinorProductCodeToTextMapping[item.PERAIMinorProductCode]),
    [PROFILE_FIELDS.PERAIDepositoryID]: textFormatter(RetirementMapping[item.PERAIDepositoryID]),
    // eslint-disable-next-line camelcase
    [PROFILE_FIELDS.PERAISecRegistered]: textFormatter(Y_N_MappingTo_Yes_No[item.PERAISecRegistered]),
    [PROFILE_FIELDS.PERAIFundStructure]: textFormatter(item.PERAIFundStructure)
  })
}
