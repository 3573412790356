import React, {useState, useCallback} from 'react'
import StockInfo from '../../Lib/StockInfoPopover/StockInfo'
import PropTypes from 'prop-types'
import styles from './PeerPEContainer.module.scss'
import PeerPE from './PeerPE'
import {formatNumber} from '../../../utils/formatter'

function PeerPEContainer ({symbol}) {
  const [showToolTip, setShowToolTip] = useState(false)
  const [toolTipPosition, setToolTipPosition] = useState({})
  const [symbolInfo, setSymbolInfo] = useState({})

  const handleHoverPopup = useCallback(
    (data) => {
      if (data.position) {
        setToolTipPosition({top: data.position.top, left: data.position.left})
      }
      if (data.symbolInfo) {
        setSymbolInfo({...data.symbolInfo})
      }
      setShowToolTip(data.show)
    },
    []
  )

  function setToolTip () {
    const tabValue = formatNumber(symbolInfo.tabValue, {
      precision: 2,
      negSign: true
    })
    return (<StockInfo symbol={symbolInfo.symbol} name={symbolInfo.name} activeTab={symbolInfo.tab} activeTabValue={tabValue} venueXid={parseInt(symbolInfo.venue)} style={toolTipPosition} />)
  }

  return (
    <div className={styles.peerPEContainerParent}>
      <PeerPE symbol={symbol} handleHoverPopup={handleHoverPopup} />
      {showToolTip && setToolTip()}
    </div>
  )
}

PeerPEContainer.propTypes = {
  symbol: PropTypes.string.isRequired
}

export default PeerPEContainer
