/* eslint-disable no-debugger */
/* eslint-disable no-eval */
import React, {useState, useEffect} from 'react'
import PropTypes from 'prop-types'
import {PAGE_NAMES, STOCK_VALUATION, URLS} from '../../../utils/appConstants'
import DataRequest from '../../../services/DataRequest'
import styles from './StockValuation.module.scss'
import {formatNumber} from '../../../utils/formatter'
import CommonTabularStructure from '../../Lib/CommonTabularStructure/CommonTabularStructure'
import {EVENT_NAMES} from '../../../utils/f2Constants'
import {registerF2OnEvent, registerAccessTokenUpdatedF2OnEvent, emitF2Event} from '../../../utils/f2Methods'
import Nodata from '../../Lib/NoData/NoData'
import StockValuationAvg from './StockValuationAvg'
function StockValuation ({symbol}) {
  const [stockSymbol, setSymbol] = useState(symbol)
  const [venueXid, setVenueXid] = useState()
  const [isIndustryAvg, setIsIndustryAvg] = useState({isLoading: true, data: false})
  const [valuationData, setValuationData] = useState(setCurrentValuationState(null))

  const [symbolData, setSymbolData] = useState({
    isError: false,
    isLoading: true,
    symbol: symbol,
    symbolData: {
      valuation: null,
      operations: null
    }
  })
  const [aggregateData, setAggregateData] = useState({
    isError: false,
    isLoading: true,
    aggregateData: {
      valuation: null,
      operations: null
    }
  })

  useEffect(() => {
    venueXid && getClassificationData(venueXid)
  }, [venueXid, symbol])

  useEffect(() => {
    getVenueXidFromXref(stockSymbol)
    registerAccessTokenUpdatedF2OnEvent(() => {
      fireEvents(stockSymbol)
    })
    registerF2OnEvent(EVENT_NAMES.SYMBOL_CHANGED_EVENT, (symbolUpdatedObject) => {
      setSymbol(symbolUpdatedObject.symbol)
      fireEvents(symbolUpdatedObject.symbol)
    })
  }, [symbol])

  function fireEvents (updatedSymbol) {
    setValuationData(setCurrentValuationState(null))
    setSymbolData({
      ...symbolData,
      isError: false,
      isLoading: true,
      symbol: null,
      data: {
        valuation: null,
        operations: null
      }
    })
    getVenueXidFromXref(updatedSymbol)
  }

  function getVenueXidFromXref (symbolParam) {
    DataRequest.execute(
      `${URLS.PLATFORM.XREFGETSYMBOL}/${symbolParam}`,
      {
        params: {
          isCaseSensitive: false
        }
      },
      xrefCallback,
      null
    )
  }
  function xrefCallback (response) {
    if (response.isError) {
      setStockValuationData(setCurrentValuationState({isData: false, isError: true}))
    }
    if (response?.data) {
      const responseData = response?.data?.xids
      if (responseData?.venue) {
        setVenueXid(responseData.venue)
        getClassificationData(responseData.venue)
      }
    } else {
      setStockValuationData(setCurrentValuationState({isData: false,
        isError: true}))
    }
  }

  function setCurrentValuationState (data) {
    let obj = {}
    let arrayObj = []
    STOCK_VALUATION.DATAMAP.forEach((item) => {
      obj = {}
      obj['label'] = item.key
      obj['value'] = getComputedValue(item, data)
      obj['column'] = item && item['column']
      arrayObj.push(obj)
    })
    return {
      isError: data ? data.isError : null,
      isData: data ? data.isData : false,
      isLoading: data ? data.isLoading : data !== null,
      data: arrayObj,
      header: STOCK_VALUATION.HEADER
      // footer: STOCK_VALUATION.FOOTER
    }
  }
  function getComputedValue (item, data) {
    let expressionValue = getEvalValue(item.value, data)
    let value = formatNumber(expressionValue, {
      precision: STOCK_VALUATION.PRECISION,
      negSign: true,
      postFix: item.type
    })
    return value
  }

  function getEvalValue (value, data) {
    let val = ''
    try {
      val = eval(value)
    } catch (ex) {
      val = null
    }
    return val
  }
  function getSetStockValuationData () {
    DataRequest.execute(`${URLS.PLATFORM.FINANCIALRATIOS}/${venueXid}`,
      {
        params: {
          includeYearEndData: true
        }
      },
      setStockValuationData,
      null
    )
  }

  function setStockValuationData (responseData) {
    const valuation = responseData && responseData.data && responseData.data.valuation
    if (valuation) {
      setSymbolData({
        ...symbolData,
        isError: false,
        isLoading: false,
        symbol: symbolData.symbol,
        data: {
          valuation: valuation,
          operations: responseData.data.operations
        }
      })
    }
    setIsIndustryAvg({isLoading: false, data: false})
    setValuationData(setCurrentValuationState(responseData && responseData.data))
  }

  function getClassificationData (venueXid) {
    DataRequest.execute(`${URLS.PLATFORM.EQUITY_CLASSIFICATION}/${venueXid}`,
      {},
      getSetClassificationData,
      null
    )
  }
  function getSetClassificationData (responseData) {
    const classification = responseData?.data?.classification
    if (classification?.industryCode) {
      getRatiosAggregateData(classification?.industryCode)
    } else {
      venueXid && getSetStockValuationData()
    }
  }

  function getRatiosAggregateData (industryCode) {
    DataRequest.execute(`${URLS.PLATFORM.RATIOS_AGGREGATE}`,
      {
        params: {
          aggregateCode: industryCode,
          countryOfExchange: 'US'
        }
      },
      getSetRatiosAggregateData,
      null
    )
  }
  function getSetRatiosAggregateData (responseData) {
    const summary = responseData && responseData.data && responseData.data.summary
    summary && summary.find(items => {
      items.aggregateType === 'Average' &&
        setAggregateData({
          isError: false,
          isLoading: false,
          data: {
            valuation: items.ratios.valuation,
            operations: items.ratios.operations
          }
        })
    })
    venueXid && getSetStockValuationData()
  }

  function handlePageClick () {
    emitF2Event(EVENT_NAMES.MOD_GOTO, {
      gotoPage: PAGE_NAMES.VALUATIONS,
      symbol: symbol}
    )
  }

  return (
    <React.Fragment>
      { <div className={styles.stockValuationContainer} >
        {
          stockSymbol && !isIndustryAvg.isLoading && !symbolData.isLoading && !aggregateData.isLoading ? <StockValuationAvg symbolData={symbolData} aggregateData={aggregateData} symbol={stockSymbol} handlePageClick={handlePageClick} />
            : !valuationData.isData ? <CommonTabularStructure dataObj={valuationData} handlePageClick={handlePageClick} /> : <Nodata />
        }
      </div>
      }
    </React.Fragment>
  )
}

export default StockValuation

StockValuation.propTypes = {
  symbol: PropTypes.string.isRequired
}
