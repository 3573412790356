/* eslint-disable no-unused-vars */
'use strict'
import React, {useState, useEffect, useRef} from 'react'
import PropTypes from 'prop-types'
import styles from './AIScreener.module.scss'
import TableWithShowMore from '../../Shared/Table/TableWithShowMore'
import TabControl from '../../Shared/TabControl/TabControl'
import { profileHeaders } from './AIData/AITableHeaders'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import DataRequest from '../../../../../../services/DataRequest'
import BasicDropDown from '../../Shared/Dropdown/BasicDropDown'
import SxSelect from '../../Shared/SxSelect/SxSelect'
import SaveScreenPopup from '../../Shared/SaveScreenerModal/SaveScreenPopup'
import ModalRoot from '../../Shared/Modal/components/ModalRoot'
import ModalService from '../../Shared/Modal/service/ModalService'
import OpenScreenerPopup from '../../Shared/OpenScreenerModal/OpenScreenerPopup'
import {CreateUUID, getKeyByValue} from '../../utils/utilities'
import Loader from '../../../../../Lib/common/Loader'
import {handleWindowResize, getDeviceType, deviceType, truncateText} from '../../../../../../utils/utilities'

import { DefaultProductFeeIndicator, PershingIBDCode, PershingPropFeeIndicator, AITABLE_FIELDS, PROFILE_FIELDS, BASICS_FILTER_OPTIONS, DEFAULT_PRE_SCREEN_AI_SCREENER_INPUTS, AI_TABS_LIST, NUM_OF_ROWS_LIMIT, FIELD_DISPLAYFIELDS_MAPPING, TabKeysAI } from './AIConstants'
import { AI_SCREEN_ID, AI_SCREEN_TYPE, URLS, MOD_GOTO_PAGES, SCREENER_TYPES, CURRENCY_CONVERSION_CONSTANTS } from '../../../components/utils/appConstants'
import ScreenerHelper from '../../Helpers/ScreenerHelper'
import { fillProfileTableColumnsData } from './AIScreenerCommonMethods'
import { emitF2Event } from '../../../../../../utils/f2Methods'
import { EVENT_NAMES } from '../../../../../../utils/f2Constants'
import ImportantDisclosure from '../../Shared/Disclosures/ImportantDisclosures/ImportantDisclosure'
import Footer from '../../Shared/Disclosures/Footer/Footer'
import TopLevelFilterDisplayName from './AIData/TopLevelFilterDisplayName.json'
import { makeUpdateScreenerApiCall, ScreenerCreatedMessage, ScreenerDetailsUpdatedMessage, screenerDropdownOptions, ScreenerOptionsConstants, UpdateScreenerConfirmationDialogConstants, UpdateScreenerConfirmationMessage } from '../../Shared/Screener/Constants'
import { fireToastMessage } from '../../../../../Lib/CustomToast.js/CustomToast'
import ConfirmationPopup from '../../../../../Watchlists/View/WatchlistStocks/ConfirmationPopup/ConfirmationPopup'
import ActionMenu from '../../../../../Lib/ActionMenu/ActionMenu'
import * as XLSX from 'xlsx';
import { formatDate } from '../../utils/formatter'
import { actionClickHandler } from '../Shared/Constants'

function AIScreener (props) {
  const [showUpdateConfirmation, setShowUpdateConfirmation] = useState(false)
  const filterNames = JSON.parse(JSON.stringify(TopLevelFilterDisplayName.filterNames))
  const [filterDisplayName, setFilterDisplayName] = useState(filterNames)
  const savedScreenName = useRef([])
  const [fundDataCount, setFundDataCount] = useState(0)
  const [isMobile, setIsMobile] = useState(false)
  const [selectedFilters, setSelectedFilters] = useState(props.selectedFilters || null)
  const selectedFiltersRef = useRef()
  const [ibd, setIbd] = useState('')
  const [basics, setBasics] = useState(props.basics || IBDCheck(BASICS_FILTER_OPTIONS, PershingIBDCode))
  const basicsRef = useRef()
  const basicDropdown = useRef()
  const [selectedFunds, setSelectedFunds] = useState([])
  const [tabSelected, setTabSelected] = useState(AI_TABS_LIST.Profile)

  const [profileTableHeaders, setProfileTableHeaders] = useState(profileHeaders)
  const [profileTableData, setProfileTableData] = useState({data: []})
  const [profileHeadersChangeId, setProfileHeadersChangeId] = useState(0)

  const [showMoreClickCount, setShowMoreClickCount] = useState(0)
  const csvInstance = useRef()
  const [csvData, setCsvData] = useState({data: [], fileName: `${AI_TABS_LIST.Profile}.csc`})

  const [errorMsg, setErrorMsg] = useState('')
  const [id, setID] = useState('')
  const [modalHeader, setModalHeader] = useState('')
  const [resetScreenerFilter, setResetScreenerFilter] = useState('')
  const screenId = useRef('')
  const [selectedScreenerId, setSelectedScreenerId] = useState()
  const [screenerName, setScreenerName] = useState(props.screenerName || 'Alternative Investments Screener')
  const [screenerDescription, setScreenerDescription] = useState('')
  const screenerNameRef = useRef()
  const [screenerArguments, setScreenerArguments] = useState(props.screenerArguments || [])
  const screenerArgumentsRef = useRef()
  const [updatedArguments, setUpdatedArguments] = useState([])
  const [isDownloading, setDownloading] = useState(false)

  const [isLoading, setLoader] = useState(true)
  const [deviceTypeItem, setDeviceType] = useState(deviceType.Desktop)

  const sortArgument = useRef([
    {
      direction: 'A',
      field: 'PERAICusip'
    }
  ])

  useEffect(() => {
    setIsMobile(getDeviceType() === deviceType.Mobile)
    setDeviceType(getDeviceType())
    handleWindowResize((deviceTypeParam) => {
      setIsMobile(deviceTypeParam === deviceType.Mobile)
      setDeviceType(getDeviceType())
    })
    getScreenData()
  }, [])

  useEffect(() => {
    selectedFiltersRef.current = selectedFilters
  }, [selectedFilters])
  useEffect(() => {
    screenerNameRef.current = screenerName
  }, [screenerName])

  useEffect(() => {
    basicsRef.current = basics
  }, [basics])

  useEffect(() => {
    screenerArgumentsRef.current = screenerArguments
  }, [screenerArguments])

  useEffect(() => {
    if (!props.tabChange) {
      getProductAccess()
    }
    getTableData(0, screenerArguments)
    return () => {
      props.persistTabChange({
        selectedFilters: selectedFiltersRef.current,
        screenerArguments: screenerArgumentsRef.current,
        basics: basicsRef.current,
        screenerName: screenerNameRef.current
      })
    }
  }, [])

  useEffect(() => {
    getTableData(0, screenerArguments)
  }, [screenerArguments])

  useEffect(() => {
    if (props.navigationFromViewAllLinkFlag) {
      ResetFilterOptions()
    }
  }, [props.navigationFromViewAllLinkFlag])

  useEffect(() => {
    setProfileTableHeaders(profileTableHeaders)
  }, [profileTableHeaders])

  const getProductAccess = () => {
    let callback = (response) => {
      if (!response.isDataRequestComplete) return

      if (!response.isError) {
        const responseData = response && response.data
        setIbd(responseData.ibd)
        setBasics(IBDCheck(BASICS_FILTER_OPTIONS, PershingIBDCode, responseData.ibd))
      }
    }

    DataRequest.execute(
      `${URLS.CUSTOM.PRODUCTACCESS}/`,
      {},
      callback,
      null
    )
  }

  function IBDCheck (basics, PershingIBDCode, IBDCode) {
    let basicsDataList = JSON.parse(JSON.stringify(basics))
    if (PershingIBDCode && PershingIBDCode.indexOf(IBDCode || ibd) === -1) {
      return basicsDataList.filter(dataList => dataList.val !== 'PERAISecurityHeldIndicator')
    }
    basicsDataList.map(
      recordItem => {
        if (recordItem.label === 'Fee' && recordItem.label === recordItem.items[0].label && recordItem.items[0].val.indexOf('PROP') === -1) {
          recordItem.items[0].val.push(PershingPropFeeIndicator)
        }
      })
    return basicsDataList
  }

  function getDynamicListNonTraded (list) {
    let obj = {}
    list.forEach(item => {
      if (item.isDynamicData === 'true') {
        obj[item.val] = ''
      }
    })
    return obj
  }
  function handleOnCheckboxChange (selection) {
    setSelectedFunds(selection)
  }

  function getTableData (offset, screenerArguments, sortArguments, defaultSorting = false) {
    setLoader(true)

    const screenDataCallback = (response) => {
      if (!response.isDataRequestComplete) return
      if (!response.isError) {
        const responseData = response.data || {}
        let tempProfileTableData = []
        responseData.items && responseData.items.map(item => {
          fillProfileTableColumnsData(tempProfileTableData, item)
        })

        let profileTableDataOld = offset > 0 ? profileTableData.data : []
        setProfileTableData({data: [...profileTableDataOld, ...tempProfileTableData]})

        if (defaultSorting) {
          sortColumnDataHandler('Name', true, false, true)
        }

        if (responseData && responseData.pagination) {
          setFundDataCount(responseData.pagination.totalItems || 0)
        } else {
          setFundDataCount(0)
        }
      }
      setLoader(false)
    }

    DataRequest.execute(
      `${URLS.CUSTOM.SCREEN + '?screenType=AI'}`,
      buildScreenInputs(offset, false, screenerArguments, sortArguments),
      screenDataCallback
    )
  }

  function resetScreener () {
    setResetScreenerFilter(CreateUUID())
    screenId.current = ''
    setScreenerName('Alternative Investments Screener')
    setSelectedFilters(null)
    setBasics(IBDCheck(BASICS_FILTER_OPTIONS, PershingIBDCode))
    setScreenerArguments([])
    setSelectedScreenerId()
  }

  function changeHandlerDropDown (e) {
    if (e.innerText === ScreenerOptionsConstants.SaveThisScreener) {
      ModalService.open(SaveScreenPopup)
      setModalHeader('Save Screener')
      setErrorMsg('')
    } else if (e.innerText === ScreenerOptionsConstants.NewScreener) {
      resetScreener()
    } else if (e.innerText === ScreenerOptionsConstants.SavedScreeners) {
      ModalService.open(OpenScreenerPopup)
      setModalHeader('Open Alternative Screener')
      setErrorMsg('')
    } else if (e.innerText === ScreenerOptionsConstants.UpdateThisScreener) {
      setShowUpdateConfirmation(true)
    }
  }

  function getScreenData () {
    let callback = (response) => {
      if (!response.isDataRequestComplete) return
      if (!response.isError) {
        savedScreenName.current = []
        response.data.forEach(element => {
          savedScreenName.current[element.id] = element.name
          if (screenId.current === element.id) {
            screenId.current = element.id
            setScreenerName(element.name)
          }
        })
      }
    }

    DataRequest.execute(
      `${URLS.PLATFORM.SAVEDSCREENS}/` + AI_SCREEN_ID,
      {},
      callback,
      null
    )
  }

  function updateScreenData (name, description) {
    basicDropdown.current.resetDropdown()
    let callback = (response) => {
      if (!response.isDataRequestComplete) return
      if (!response.isError) {
        setUpdatedArguments([])
        fireToastMessage({
          message: ScreenerDetailsUpdatedMessage
        })
        if (screenId.current === selectedScreenerId) {
          setScreenerName(name)
        }
      }
    }
    const postData = {
      id: screenId.current,
      arguments: updatedArguments || [{screenArguments: screenerArguments,
        savedFilters: selectedFilters}],
      name: name,
      description: description
    }
    makeUpdateScreenerApiCall(AI_SCREEN_ID, postData, callback)
  }

  const buildScreenInputs = (_offset, isExportToCsv, screenerArguments, sortArguments) => {
    const preScreenInputs = {...DEFAULT_PRE_SCREEN_AI_SCREENER_INPUTS}
    preScreenInputs.offset = _offset
    if (screenerArguments.filter(x => x.field === 'PERAIProductFeeIndicator').length === 0) {
      let defaultArgs = {
        field: 'PERAIProductFeeIndicator',
        conditions: [{'operator': 'Like', 'values': DefaultProductFeeIndicator}]
      }
      screenerArguments.push(defaultArgs)
    }

    preScreenInputs.arguments = screenerArguments || []

    if (sortArguments) {
      preScreenInputs.sortArguments = sortArguments
    }

    // preScreenInputs.limit = preScreenInputs.limit

    if (isExportToCsv) {
      preScreenInputs.limit = fundDataCount
    }
    preScreenInputs.resultFields = Object.keys(AITABLE_FIELDS)

    return {
      method: 'POST',
      postData: preScreenInputs
    }
  }

  function updateScreenerArgument (itemlist, selectedParent) {
    let controls = ['slider', 'rangeSelector']
    let screenerColumnName = getScreenerCoumnName(itemlist, selectedParent)

    if (controls.find(f => f === itemlist.control)) {
      if (itemlist.selectedValue === '' || itemlist.control === 'slider') {
        ScreenerHelper.buildControlArgument(screenerColumnName, itemlist.min, itemlist.max, itemlist.isSelected, screenerArguments, itemlist)
      } else {
        let value = {selectedVal: itemlist.selectedValue}
        let { calcMin, calcMax } = ScreenerHelper.getMinMaxValues(value)
        ScreenerHelper.buildControlArgument(screenerColumnName, calcMin, calcMax, itemlist.isSelected, screenerArguments, itemlist)
      }
    } else if ((itemlist.control === 'checkbox' || itemlist.control === 'radio') && itemlist.startDate && itemlist.endDate) {
      if (selectedParent.control === 'submenu') {
        ScreenerHelper.buildControlArgument(screenerColumnName, itemlist.startDate, itemlist.endDate, itemlist.isSelected, screenerArguments, itemlist)
      } else {
        ScreenerHelper.buildControlArgument(screenerColumnName, itemlist.startDate, itemlist.endDate, itemlist.isSelected, screenerArguments, itemlist)
      }
    } else {
      if (selectedParent.control === 'submenu') {
        ScreenerHelper.buildScreenArguments(screenerColumnName, itemlist.val, itemlist, screenerArguments)
      } else {
        if (screenerColumnName === 'PERAIProductFeeIndicator') {
          if (screenerArguments[0].conditions[0].values.length === DefaultProductFeeIndicator.length) {
            screenerArguments[0].conditions[0].values = typeof (itemlist.val) === 'string' ? [itemlist.val] : itemlist.val
          } else if (itemlist.label === 'Fee') {
            itemlist.val = itemlist.val.filter((ele) => ele !== 'NTFP')
          }
        }
        ScreenerHelper.buildScreenArguments(screenerColumnName, itemlist.val, itemlist, screenerArguments)
      }
    }
    setScreenerArguments([...screenerArguments])
    return screenerArguments
  }

  function getScreenerCoumnName (child, parent) {
    if ((child.control === 'slider' || child.control === 'rangeSelector') && child.parentVal.indexOf('PERM') > -1) {
      return child.parentVal
    } else if (parent.val.indexOf('PERM') === -1) {
      return parent.parentVal
    }
    return parent.val
  }

  function showMoreClickHandler () {
    setShowMoreClickCount(showMoreClickCount + 1)
    const _offset = (10 * (showMoreClickCount + 1))
    getTableData(_offset, screenerArguments, sortArgument && sortArgument.current)
  }

  function editScreenData (item) {
    ModalService.open(SaveScreenPopup, {screenData: {isEditMode: true, defaultName: item.name, defaultDescription: item.description}})
    setModalHeader('Edit Screener Details')
    setUpdatedArguments(item.arguments)
    screenId.current = item.id
    setErrorMsg('')
  }

  function saveBtnHandler (isSave, name, description, isEditMode) {
    if (name.trim() === '') {
      setErrorMsg('Name cannot be empty.')
    } else if (!isSave) {
      setErrorMsg('There is already a screener with this name.')
    } else {
      ModalService.open() // Closes the opened modal
      if (isEditMode) {
        updateScreenData(name, description)
      } else {
        let savedId = CreateUUID()
        screenId.current = savedId
        basicDropdown.current.resetDropdown()
        let callback = (response) => {
          if (!response.isDataRequestComplete) return
          if (!response.isError) {
            setScreenerName(name)
            setScreenerDescription(description)
            setSelectedScreenerId(savedId)
            props.updateScreenerName(name)
            getScreenData()
            fireToastMessage({
              message: ScreenerCreatedMessage
            })
          }
        }
        DataRequest.execute(
          `${URLS.PLATFORM.SAVEDSCREENS}/` + AI_SCREEN_ID,
          {
            method: 'POST',
            postData: {
              id: savedId,
              arguments: [{screenArguments: screenerArguments,
                basicsFilter: basics,
                selFilters: selectedFilters}],
              name: name,
              description: description
            }
          },
          callback,
          null
        )
      }
    }
  }

  function deleteScreenerHandler (savedScreenerId) {
    delete savedScreenName.current[savedScreenerId]
    if (savedScreenerId === selectedScreenerId) {
      ResetFilterOptions()
    }
  }

  function closeHandler () {
    basicDropdown.current.resetDropdown()
  }

  function textChange () {
    setErrorMsg('')
  }

  const syncFixedColumns = React.useRef(null)

  function getFilterLabel (filter) {
    switch (filter.control) {
      case 'checkbox':
        return filter.label
      case 'radio':
        return filter.selectedValue
      case 'slider':
        return filter.val
      default:
        return filter.label
    }
  }

  function addPill (itemlist, selectedParent, parents, elementId) {
    const templateObject = {}
    templateObject[elementId] = {
      'L2': {},
      'L3': {}
    }

    let tempSelectedFilters = (selectedFilters && selectedFilters[elementId]) ? selectedFilters : templateObject

    if (selectedParent.items && selectedParent.items.length === 0) {
      return
    }

    for (let index = 0; index < selectedParent.items.length; index++) {
      let filter = selectedParent.items[index]

      tempSelectedFilters[selectedParent.rootFilterId][filter.level] = {
        ...tempSelectedFilters[selectedParent.rootFilterId][filter.level],
        [filter.pillLabel + '_' + filter.label]: {
          'isSelected': filter.isSelected === true,
          'label': getFilterLabel(filter),
          'pillLabel': filter.pillLabel,
          'level': filter.level,
          'val': filter.val,
          'parentVal': selectedParent.parentVal
        }
      }
    }
    setSelectedFilters({...selectedFilters, ...tempSelectedFilters})

    let basicsDataList = IBDCheck(basics, PershingIBDCode)
    basicsDataList.forEach(record => {
      UpdateItemsCount(record)
    })
    basicsRef.current = basicsDataList
    setBasics(basicsDataList)

    if (elementId) updateTopFilterCount(elementId)
    updateScreenerArgument(itemlist, selectedParent)
  }

  function updateTopFilterCount (elementId) {
    let updateParentNode = getStateById(elementId)
    let newFilterNames = [...filterDisplayName]
    const selected = updateParentNode.map(item => item.selectedItemCount)
    if (selected.length) {
      let selectedItemCount = selected.reduce((prev, next) => prev + next)
      let element = newFilterNames.find(f => f.val === elementId)

      if (element && parent) {
        if (!element.val) element.val = parent.parentVal
        if (document.getElementById(element.val)) {
          document.getElementById(element.val).innerHTML = element.displayLabel
          if (selectedItemCount > 0) {
            newFilterNames.displayLabel = `${element.label} (${selectedItemCount})`
            setFilterDisplayName(newFilterNames)
            document.getElementById(element.val).innerHTML = `${element.displayLabel} (${selectedItemCount})`
          }
        }
      }
    }
  }

  function getStateById (elementId) {
    switch (elementId) {
      case 'basics-select':
        return basicsRef.current
      default:
        return []
    }
  }

  function hide (rootLabel, label, item, parent) {
    let tempSelectedFilters = {...selectedFilters}
    if (!tempSelectedFilters) {
      return
    }
    if (tempSelectedFilters[rootLabel] && tempSelectedFilters[rootLabel]['L2'] && tempSelectedFilters[rootLabel]['L2'][label]) {
      tempSelectedFilters[rootLabel]['L2'] = {
        ...tempSelectedFilters[rootLabel]['L2'],
        [label]: {
          ...[label],
          'isSelected': false,
          'checked': false
        }
      }
      setSelectedFilters({...tempSelectedFilters})
      let basicsDataList = IBDCheck(basics, PershingIBDCode)
      basicsDataList.forEach(record => {
        record.items && record.items.forEach(recordItem => {
          if (getFilterLabel(recordItem) === item.label) {
            recordItem.isSelected = false
            recordItem.checked = false
            if (recordItem.control === 'radio') {
              recordItem.selectedValue = null
              recordItem.val = null
            }
          }
        })
        UpdateItemsCount(record)
      })
      basicsRef.current = basicsDataList
      setBasics(basicsDataList)
    }
    updateTopFilterCount(rootLabel)
    let updatedScreenerArg = []
    screenerArguments.forEach(itemArg => {
      if (itemArg.field === item.parentVal) {
        let hideElemIndex = itemArg.conditions[0].values.indexOf(item.val)
        if (hideElemIndex > -1) {
          itemArg.conditions[0].values.splice(hideElemIndex, 1)
          if (itemArg.conditions[0].values.length > 0) {
            updatedScreenerArg.push(itemArg)
          }
        }
      } else {
        updatedScreenerArg.push(itemArg)
      }
    })
    setScreenerArguments(updatedScreenerArg)
  }

  function UpdateItemsCount (parent) {
    let categoryCount = parent.items.filter(x => x.isSelected).length
    updateElementFilterCount(parent, categoryCount)
    parent.selectedItemCount = categoryCount

    let l3FiltersSelected = 0
    let isSubMenu = false
    // eslint-disable-next-line no-unused-vars
    for (let [index2, subMenu] of parent.items.filter(x => x.control === 'submenu').entries()) {
      let categoryCount = subMenu.items.filter(x => x.isSelected).length
      subMenu.selectedItemCount = categoryCount
      l3FiltersSelected = l3FiltersSelected + categoryCount
      updateElementFilterCount(subMenu, categoryCount)
      isSubMenu = true
    }

    if (isSubMenu) {
      updateElementFilterCount(parent, l3FiltersSelected)
      parent.selectedItemCount = l3FiltersSelected
    }
  }

  function updateElementFilterCount (parent, selectedItemCount) {
    if (parent.pillLabel) {
      parent.pillDisplayLabel = `${parent.pillLabel}`
    } else {
      parent.pillDisplayLabel = `${parent.label}`
    }
    // Need to remove this and handle it properly
    if (document.getElementById(`li-${parent.parentVal}-${parent.val}`)) {
      document.getElementById(`li-${parent.parentVal}-${parent.val}`).childNodes[0].innerHTML = parent.pillDisplayLabel
      if (selectedItemCount > 0) {
        document.getElementById(`li-${parent.parentVal}-${parent.val}`).childNodes[0].innerHTML = `${parent.pillDisplayLabel} (${selectedItemCount})`
      }
    }
  }

  // Handler of screener from saved one
  function openBtnHandler (results) {
    let matchingId = Object.keys(savedScreenName.current).find(key => savedScreenName.current[key] === results.name)
    screenId.current = matchingId
    setSelectedScreenerId(results.id)
    setScreenerName(results.name)
    setScreenerDescription(results.description)
    props.updateScreenerName(results.name)
    setSelectedFilters(results.arguments[0].selFilters)
    setBasics(results.arguments[0].basicsFilter)
    setScreenerArguments(results.arguments[0].screenArguments)
    // Need to remove this and handle it properly
    document.querySelector('#openScreenModal button').click()
  }

  function renderActionMenu () {
    return <div className={styles.openSaveOverlay} role={'navigation'}>
      {
        <BasicDropDown
          data={screenerDropdownOptions(selectedScreenerId)}
          selectedValue={'Open & Save'}
          ref={basicDropdown}
          changeHandler={(e) => changeHandlerDropDown(e)}
          isMobileView={isMobile}
          caretColor={'white'}
          isBlueBackground={!isMobile}
        />
      }
    </div>
  }

  function renderSelectedFilters () {
    let tempAnyFilterSelected
    let filtersHTML = <div className={styles.pill}> {
      selectedFilters && Object.keys(selectedFilters).map((rootFilter) => {
        // redner L2
        let arrL2 = selectedFilters[rootFilter]['L2']
        // let parentObj = {}
        // parentObj['items'] = Object.keys(arrL2).map(key => arrL2[key])
        return Object.keys(arrL2).map((filter) => {
          if (arrL2[filter].isSelected === true) {
            tempAnyFilterSelected = true
            return <div className={styles.buttonfilter} aria-label={filter}>
              {
                `${arrL2[filter].pillLabel}: ${arrL2[filter].label}`
              }
              <span role='button' tabIndex='0' aria-hidden='true' onClick={(e) => hide(rootFilter, filter, arrL2[filter], arrL2)}>X</span>
            </div>
          }
        })
      })
    }
    </div>

    if (!tempAnyFilterSelected) {
      return <div className={styles.pill}>
        <div className={styles.buttonfilter} aria-label={'All Alternative Investments'}>
          {'All Alternative Investments'}
        </div>
      </div>
    }

    return filtersHTML
  }

  function ResetFilterOptions () {
    setResetScreenerFilter(CreateUUID())
    screenId.current = ''
    setScreenerName('Alternative Investments Screener')
    setScreenerDescription('')
    setSelectedScreenerId()
    props.updateScreenerName('')
    setSelectedFilters(null)
    setBasics(IBDCheck(BASICS_FILTER_OPTIONS, PershingIBDCode))
    setScreenerArguments([])
  }

  function renderFilterDDLs () {
    return <div className={styles.screenerFiltersContainer} id='screenerFiltersContainer' aria-label={SCREENER_TYPES.AI_SCREENER.ARIA_LABEL} role={'navigation'} name={'screenerFiltersContainer'}>
      <div className={styles.filterSectionAlign} id='filterSectionAlign'>
        <div className={styles.selectScreenerFilter} >
          <SxSelect
            label='basics-select'
            deviceType={deviceTypeItem}
            id='basics-select'
            addPill={addPill}
            list={basics}
            className={styles.selectScreenerFilter} title={'Basics'} />
        </div>
      </div>

      <div className={styles.selectedFilterPills}>
        {
          isMobile && <div className={styles.rightPills}>
            <span className={styles.dataCount}>{fundDataCount} Results</span>
            <span role='button' tabIndex='0' className={styles.resetFilters} onKeyDown={(e) => ResetFilterOptions()} onClick={() => ResetFilterOptions()}>Reset Filters</span>
          </div>
        }
        {
          renderSelectedFilters()
        }
        {
          !isMobile && <div className={styles.rightPills}>
            <span className={styles.dataCount}>{fundDataCount} Results</span>
            <span role='button' tabIndex='0' className={styles.resetFilters} onKeyDown={(e) => ResetFilterOptions()} onClick={() => ResetFilterOptions()}>Reset Filters</span>
          </div>
        }
      </div>
    </div>
  }

  function updateScreenerConfirmationHandler () {
    setShowUpdateConfirmation(false)
    basicDropdown.current.resetDropdown()
    let callback = (response) => {
      if (!response.isDataRequestComplete) return
      if (!response.isError) {
        setLoader(false)
        fireToastMessage({
          message: UpdateScreenerConfirmationMessage
        })
      }
    }
    const postData = {
      id: screenId.current,
      arguments: [{screenArguments: screenerArguments,
        basicsFilter: basics,
        selFilters: selectedFilters}],
      name: screenerName,
      description: screenerDescription
    }
    makeUpdateScreenerApiCall(AI_SCREEN_ID, postData, callback)
  }

  function sortColumnDataHandler (_columnName, _sortDirection, comingFromChild, defaultSorting = false) {
    if (comingFromChild) {
      _sortDirection = _sortDirection === undefined ? false : _sortDirection
      let sortObj = {
        direction: _sortDirection ? 'D' : 'A',
        field: getKeyByValue(FIELD_DISPLAYFIELDS_MAPPING[tabSelected], _columnName)
      }
      getTableData(0, screenerArguments, [sortObj])
      setShowMoreClickCount(0)
      sortArgument.current = [sortObj]
    }

    let allTabHeaders = [
      {tab: AI_TABS_LIST.Profile, key: profileTableHeaders}
    ]

    if (comingFromChild || defaultSorting) {
      allTabHeaders.forEach(obj => {
        obj.key.map(function (item) {
          delete item.SortDirection
          return item
        })
        obj.key.forEach(item => {
          if (item.name === _columnName) {
            item.SortDirection = (tabSelected === obj.tab) ? _sortDirection : !_sortDirection
          }
        })
      })
    }

    setProfileTableHeaders(allTabHeaders[0].key)
    setProfileHeadersChangeId(Math.random())
  }

  let debounce = (method, delay) => {
    clearTimeout(method._tId)
    method._tId = setTimeout(function () {
      method()
    }, delay)
  }

  function SetCsvDataForExport (items, tabKey = tabSelected) {
    let tempData = []
    switch (tabKey) {
      case AI_TABS_LIST.Profile:
        items.map(item => {
          fillProfileTableColumnsData(tempData, item)
        })
        break
    }
    return tempData.map(function (item) {
      delete item.searchType
      if (tabSelected !== AI_TABS_LIST.Profile) {
        delete item.CUSIP
      }
      return item
    })
  }

  function getExportTableData () {
    const screenDataCallback = (response) => {
      let workbook = XLSX.utils.book_new();
      if (!response.isDataRequestComplete) return
      if (!response.isError) {
        const responseData = response.data || {}
        let temp = []
        temp = SetCsvDataForExport(responseData.items)
        const outputArray = [];
        if (temp.length) {
          outputArray.push(Object.keys(temp[0]));
          temp?.forEach(obj => {
            const row = Object.values(obj);
            outputArray.push(row);
          });
        }
          const workSheet = XLSX.utils.aoa_to_sheet(outputArray);
          XLSX.utils.book_append_sheet(workbook, workSheet, tabSelected)
          const fileName = tabSelected.replace(" ", "") + " " + formatDate(Date.now(), {format: 'MM/DD/YYYY'}).replace(/\//g, '.') + ".xlsx";
        XLSX.writeFile(workbook, fileName);
        setDownloading(false);
      }
    }
    DataRequest.execute(
      `${URLS.CUSTOM.SCREEN + '?screenType=AI'}`,
      buildScreenInputs(0, true, screenerArguments, sortArgument.current),
      screenDataCallback
    )
  }

  function DownloadCsv () {
    setDownloading(true)
    getExportTableData()
  }

  function handleDownloadCsv (e) {
    if (e.key === 'Enter') {
      debounce(DownloadCsv, 1000)
    }
  }

  function getShowMoreClickRows () {
    return (fundDataCount - (NUM_OF_ROWS_LIMIT * (showMoreClickCount + 1))) > 0 ? (fundDataCount - (NUM_OF_ROWS_LIMIT * (showMoreClickCount + 1))) : 0
  }

  return (
    <div key={resetScreenerFilter} className={styles.AIScreenerContainer} id='main'>
      <div className={styles.screenerTitle}>
        <h2 aria-label='Discover Alternative Investments'>Discover Alternative Investments</h2>
        <div role='button' tabIndex='0' id='screenName' className={styles.savedscreenName} aria-label={screenerName}>{deviceTypeItem === deviceType.Desktop ? screenerName : truncateText(screenerName, 25)} </div>
      </div>
      {
        renderActionMenu()
      }
      {
        renderFilterDDLs()
      }

      <div className={`${styles.screenerTitle} ${styles.allModels}`}>
        <div>All Alternative Investments ({fundDataCount}) { isLoading && <span><Loader /></span> }</div>
      </div>
      <div className={styles.downloadCompareButtonWrapperDiv}>
        <div className={styles.compareAndDownloadContainer}>
          {isDownloading ? <span className={styles.loader}>
            <Loader spinnerSize={'2x'} msg={'Loading'} />
          </span>
            : <span aria-label='Download CSV' data-testid='downloadCSV' className={`${styles.displayHidden} ${styles.headerActionMenu}`} onKeyDown={(e) => handleDownloadCsv(e)} role='button' tabIndex='0'>
              <FontAwesomeIcon icon={['fas', 'download']} className={styles.downLoadIcon} onClick={DownloadCsv} />
            </span>
          }
        </div>
      </div>
      <div className={styles.tabControl}>
        {
          <TabControl id={'AIScreener_tab'} ariaLabel={'All Alternative Investments tabs'} activeTab={tabSelected}
            changeHandler={(e) => setTabSelected(e)}
          >

            <div key={`${AI_TABS_LIST.Profile}`} label={`${AI_TABS_LIST.Profile}`}>
              {
                tabSelected === AI_TABS_LIST.Profile && <TableWithShowMore
                  key='AIScreener'
                  chkboxSelectedIds={selectedFunds}
                  ModelScreenerData={profileTableData}
                  headers={PershingIBDCode.indexOf(ibd) === -1 ? profileTableHeaders.filter(x => x.name !== 'Held Security') : profileTableHeaders}
                  defaultSortColumn={'SortingName'}
                  handleOnCheckboxChange={handleOnCheckboxChange}
                  maxSelectionLength={NUM_OF_ROWS_LIMIT}
                  moduleType={tabSelected}
                  fixedColumnCount={3}
                  moduleName={'Profile'}
                  showMoreClick={() => showMoreClickHandler()}
                  showMoreClickRows={getShowMoreClickRows()}
                  syncFixedColumns={syncFixedColumns}
                  sortColumnData={sortColumnDataHandler}
                  headersKeyChanged={profileHeadersChangeId}
                />
              }
            </div>
          </TabControl>
        }
      </div>

      <ModalRoot
        header={modalHeader}
        bodyLabel='Screener Name'
        bodyDescription='Description'
        errorMsg={errorMsg}
        saveBtnHandler={(isSave, name, description, isEditMode) => saveBtnHandler(isSave, name, description, isEditMode)}
        deleteBtnHandler={(savedScreenerId) => deleteScreenerHandler(savedScreenerId)}
        openBtnHandler={(name) => openBtnHandler(name)}
        closeHandler={() => closeHandler()}
        savedScreenName={savedScreenName.current}
        screenId={AI_SCREEN_ID}
        textChange={() => textChange()}
        showActionKebabMenu
        editScreenData={editScreenData}
      />
      { showUpdateConfirmation &&
      <div id='UpdateScreenerConfirmation'>
        <ConfirmationPopup
          headerInfo={UpdateScreenerConfirmationDialogConstants.HeaderInfo}
          confirmationHandler={updateScreenerConfirmationHandler}
          closeHandler={() => {
            setShowUpdateConfirmation(!showUpdateConfirmation)
            closeHandler()
          }}
          bodyContent={UpdateScreenerConfirmationDialogConstants.BodyContent}
          confirmButtonText={UpdateScreenerConfirmationDialogConstants.ConfirmButtonText}
          cancelButtonText={UpdateScreenerConfirmationDialogConstants.CancelButtonText}
        />
      </div>}
      <footer>
        <ImportantDisclosure />
        <Footer />
      </footer>
    </div>
  )
}
AIScreener.propTypes = {
  navigationFromViewAllLinkFlag: PropTypes.number,
  persistTabChange: PropTypes.func,
  screenerArguments: PropTypes.array,
  selectedFilters: PropTypes.object,
  basics: PropTypes.object,
  tabChange: PropTypes.bool,
  screenerName: PropTypes.string,
  updateScreenerName: PropTypes.func
}
export default AIScreener
